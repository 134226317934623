import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import NavBar from './../../component/Navbar/Navbar'
import Contact from './../SinglePage/Contact/Contact'
import Sidemenu from './../../component/Sidemenu/Sidemenu'
import './ProductDetail.css'

export default function ProductDetail() {
  let history = useHistory()
  return (
    <div>
      <Sidemenu>
        <div className="row productdetail-content">
          <div>Dashboard</div>
        </div>
      </Sidemenu>
      {/* <Contact /> */}
    </div>
  )
}
