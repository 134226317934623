import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  activityBg: {
    width: '100%',
    height: '1100px',
    backgroundColor: '#272727'
  },
  cardActivity: {
    borderRadius: '5px',
    height: '350px',
    maxWidth: '500px',
    backgroundColor: '#ffffff',
    textAlign: 'left',
    transition: '0.3s',
    margin: 'auto',
    color: '#000000',
    padding: 0,
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0 4px 16px 0 rgba(255, 255, 255, 0.5)',
      transform: 'scale(1.05)'
    }
  },

  '@keyframes animatebottom': {
    from: {
      top: '100%'
    },
    to: {
      top: 0
    }
  },

  activityDetail: {
    position: 'absolute',
    zIndex: 10,
    height: '348px',
    width: '498px',
    backgroundColor: '#272727f1',
    animation: '$animatebottom 0.5s',
    color: '#ffffff',
    opacity: 1,
    display: 'none'
  },

  activityDetailTitle: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '20px',
  },
  activityDetailDesc: {
    marginTop: '10px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px'
  },
  cardImgTopActivity: {
    height: '285px',
    width: 'auto'
  },
  cardFooterActivity: {
    height: '65px',
    textAlign: 'center'
  },
  activityTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '23px'
  },
  activityDate: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px'
  },
  slide: {
    width: '70%',
    margin: 'auto',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  }
}))