import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#333333'
  },
  menuButtonTop: {
    marginRight: theme.spacing(2),
    color: '#ffffff'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  imageIcon: {
    width: '50px',
    height: '50px',
    marginLeft: 0,
    borderRadius: '10px',
    cursor: 'pointer'
  },
  NavbarTop: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
    position:'absolute'
  },
  Navbar: {
    backgroundColor: '#ffffff',
    position:'fixed'
  },
  MenuItemTop: {
    minWidth: '150px',
    cursor: 'pointer',
  },
  MenuItem: {
    minWidth: '150px',
    cursor: 'pointer',
  },
  textItemTop: {
    margin: 'auto',
    color: '#ffffff',
    fontWeight: 'bold',
    userSelect: 'none',
    fontSize: '20px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      textShadow: '2px 2px 16px #ffffff',
      color: '#ffffff',
      transform: 'scale(1.05)',
    }
  },
  textItem: {
    margin: 'auto',
    color: '#333333',
    userSelect: 'none',
    fontSize: '15px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      color: '#2300e9',
    }
  },
  navbarMinHeight:{
    minHeight: '100px'
  },
  menuItemPadding: {
    paddingTop:'20px'
  },
  gridMenu: {
    display:'flex',
    justifyContent:'center'
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}))