import React from 'react'
import { Box, Grid, Card, CardActions, CardContent, CardMedia, CardActionArea, Button, Link } from '@material-ui/core'
import Slider from 'react-slick'
import { useStyles } from './Product.style'
import { useHistory } from 'react-router-dom'

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1980,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1620,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: true
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    },
    {
      breakpoint: 565,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false
      }
    }
  ]
}

const dataList = [
  {
    imagePath: `${process.env.PUBLIC_URL}/image/images/cruzr-1.jpg`,
    name: 'Cruzr',
    description: 'Cruzr is a cloud-based intelligent humanoid robot that provides a new generation with service applications for a variety of industries and domestic environments.',
    category: 'Robot'
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/images/ocr.jpg`,
    name: 'OCR',
    description: 'Optical character recognition, or OCR, defines the process of mechanically or electronically converting scanned images of handwritten, typed or printed text into machine-encoded text.',
    category: 'Text Analysis'
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/images/sentiment.jpg`,
    name: 'Sentiment',
    description: 'Sentiment Analysis is used to discover people’s opinions, emotions and feelings about a product or service. In theory it is a computational study of opinions, sentiments, attitudes, views, emotions etc. expressed in text.',
    category: 'Text Analysis'
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/product-ailab.png`,
    name: 'AI Lab Product',
    description: 'Detail of products',
    category: 'Name of category'
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/product-ailab.png`,
    name: 'AI Lab Product',
    description: 'Detail of products',
    category: 'Name of category'
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/product-ailab.png`,
    name: 'AI Lab Product',
    description: 'Detail of products',
    category: 'Name of category'
  },
]

export default function Product() {
  const classes = useStyles()
  let history = useHistory()

  return (
    <div className={classes.productBg}>
      <div className="text-title">
        Product
      </div>
      <div className={classes.slide}>
        <Slider {...settings}>
          {dataList.map((item: any, index:any) => {
            return (
              <Box key={`product-${index}`}>
                <Card className={classes.cardProduct}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.cardImgTopProduct}
                      image={item.imagePath}
                    />
                    <CardContent style={{ height: '200px' }}>
                      <Box className={classes.cardTitleProduct}>
                        {item.name}
                      </Box>
                      <Box className={classes.cardTextProduct}>
                        {item.description}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardFooterProduct}>
                    Category : <a>{item.category}</a>
                  </CardActions>
                </Card>
              </Box>
            )
          })}
        </Slider>
      </div>
      <div style={{ marginTop: 50 }}>
        <Link href="/product-detail/cruzr">
        <Button variant="contained" color="primary" className="btn product-button"
        // onClick={() => history.push("/product-detail/cruzr")}
        >
          Go to Our Products
        </Button>
        </Link>
      </div>
    </div>
  )
}
