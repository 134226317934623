import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './Home.style'

export default function Home() {
  const classes = useStyles()

  return (
    <Box style={{ background: 'rgba(0, 0, 0, 0.3)', color: 'white' }}>
      <Box className="home-bg" style={{ background: `url(${process.env.PUBLIC_URL}/image/pexels-pixabay-1920-1080-opacity.jpg` }}>
        <Grid container>
          <Grid item lg={6} md={12} xs={12}>
            <Box className={classes.homeBox}>
              <Box>
                <img src={`${process.env.PUBLIC_URL}/image/public_a-logo_t.png`} alt="public_a-logo_t" style={{ marginTop: 80, width: 198, height: 228 }} />
              </Box>
              <Box className={classes.homeCompany}>AI LAB COMPANY LIMITED</Box>
              <Box className={classes.homeDetail}>Let us build the product for you to get better results in the future</Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <Box className={classes.sectionDesktop}>
              <img src={`${process.env.PUBLIC_URL}/image/Cruzr-ailab.png`} alt="Cruzr-ailab" style={{ marginTop: 100, width: '100%', height: 949 }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
