import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  homeBox: {
    width: '80%',
    height: '741px',
    margin: 'auto',
    marginTop: '130px',
    [theme.breakpoints.down('xs')]: {
      height: '850px',
      marginTop: '10px',
    },
  },
  homeBoxMobile: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '130px',
    }
  },
  homeCompany: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '50px',
    lineHeight: '59px',
    marginTop: '60px'
  },
  homeDetail: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '41px',
    width: '90%',
    margin: '90px auto'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))