import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './Contact.style'

export default function Contact() {
  const classes = useStyles()

  return (
    <Box className={classes.contactBg}>
      <Box className="text-title">
        Contact Us
      </Box>
      <Box className={classes.contactVerticalStraightLine}></Box>
      <Grid container>
        <Grid item xs={12} md={12} lg={6}>
          <Grid container>
            {/* <Grid item xs={1} /> */}
            <Grid item xs={12} md={6} style={{textAlign:'left'}}>
              <Box className={classes.cantactCompany}>
                AI LAB COMPANY LIMITED
              </Box>
              <Box className={classes.contactAddress}>
                <span style={{ fontWeight: 'bold' }}>Address:</span> 25 Soi Charoen Nakhon 14, Charoen Nakhon road,Ton Sai canal,Khlong San,Bangkok 10600
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <div className="fb-page" data-href="https://www.facebook.com/Ai-Lab-Company-Limited-112972743524073"
                  data-tabs="timeline" data-width="" data-height="" data-small-header="false"
                  data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"
                  style={{ border: 0, marginTop: 120 }} >
                  <blockquote cite="https://www.facebook.com/Ai-Lab-Company-Limited-112972743524073"
                    className="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/Ai-Lab-Company-Limited-112972743524073">
                      Ai-Lab Company Limited
                  </a>
                  </blockquote>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.937992837871!2d100.50496721527382!3d13.722203901674664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e298c29d658f97%3A0x71e62705b1fe4cfa!2zMjUg4LmA4LiI4Lij4Li04LiN4LiZ4LiE4LijMTQg4LmB4LiC4Lin4LiHIOC4hOC4peC4reC4h-C4leC5ieC4meC5hOC4l-C4oyDguYDguILguJXguITguKXguK3guIfguKrguLLguJkg4LiB4Lij4Li44LiH4LmA4LiX4Lie4Lih4Lir4Liy4LiZ4LiE4LijIDEwNjAw!5e0!3m2!1sth!2sth!4v1604045348265!5m2!1sth!2sth"
              title="google map" className={ classes.contactMap } allowFullScreen aria-hidden="false" tabIndex={0}></iframe>
          </Box>
          <Box style={{ marginTop: 20, marginBottom: 20 }}>
            Our Location
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
