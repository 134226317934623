import React from 'react'
import { Box, Grid, Card, CardActions, CardContent, CardMedia, CardActionArea, Divider, Typography } from '@material-ui/core'
import Slider from 'react-slick'
import { Link, useHistory } from 'react-router-dom'
import Sidemenu from './../../../component/Sidemenu/Sidemenu'
import { useStyles } from './Cruzr.style'

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false,
  responsive: [

    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false
      }
    }
  ]
}

const dataList = [
  {
    imagePath: `${process.env.PUBLIC_URL}/image/images/360_1.jpg`,
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/images/Armss.jpg`,
  },
  {
    imagePath: `${process.env.PUBLIC_URL}/image/images/Natural.jpg`,
  },
]

export default function Cruzr() {
  const classes = useStyles()
  return (
    <div>
      <Sidemenu>
        <Box className={classes.productdetailContent}>
          <Grid container>
            <Grid item xs={10} md={3} lg={2}>
              <Box className={classes.productTopic}>Cruzr</Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container >
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Box style={{ marginBottom: '50px' }}>
              <h3 style={{ display: 'contents', fontWeight: 'bold' }}>Cruzr</h3> is a cloud-based intelligent humanoid robot that provides a new generation with service applications for a variety of industries and domestic environments.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '70px' }} >
          <Grid item xs={12} md={3} lg={3} />
          <Grid item xs={12} md={6} lg={6}>
            <video src={`${process.env.PUBLIC_URL}/image/images/cruzr.mp4`} controls autoPlay loop width="100%" />
          </Grid>
          <Grid item xs={12} md={3} lg={3} />
        </Grid>

        <Grid container>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <img src={`${process.env.PUBLIC_URL}/image/images/funtion.jpg`} width="100%" />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container className={classes.gridSlide}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <div className={classes.slide}>
              <Slider {...settings}>
                {dataList.map((item: any, index: any) => {
                  return (
                    <Box key={`cruzr-${index}`}>
                      <Card className={classes.cardCruzr}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.cardImgTopProduct}
                            image={item.imagePath}
                          />
                        </CardActionArea>
                      </Card>
                    </Box>
                  )
                })}
              </Slider>
            </div>
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <h2>Proprietary SLAM Technology</h2>
                <Box>
                  U-SLAM is an abbreviation of UBTECH SLAM (simultaneous localization and mapping), which uses real-time positioning and mapping to avoid obstacles.
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <img src={`${process.env.PUBLIC_URL}/image/images/cruzr-1.jpg`} width="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <h2>Multimodal Interaction</h2>
                <Box>
                  Cruzr with "multimodal interaction" integrates various interaction modes. The multi-modal synergies and coordination make the interaction more fluid and more humanlike, which greatly enhances the user experience.
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <img src={`${process.env.PUBLIC_URL}/image/images/multi.png`} width="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <h2>U-Meeting Video Call System</h2>
            <Box>
              U-Meeting provides voice and video functions that supports cloud computing and uses a high-definition audio and video to ensure smooth transmission. It also provides video conferencing and video-based customer service.
            </Box>
            <Box>
              <img src={`${process.env.PUBLIC_URL}/image/images/u-meeting.png`} width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Box>
              <img src={`${process.env.PUBLIC_URL}/image/images/bigdata.jpg`} width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <h2>Mobile Surveillance Camera</h2>
            <Box>
              Real-time monitoring of the region, regulatory service quality and site safety, equipped with an infrared camera that allows it to work at night.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Box>
              <img src={`${process.env.PUBLIC_URL}/image/images/Centralized.jpg`} width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <h2>Customizable Service</h2>
            <Box>
              Based on the open SDK interfaces for body movements, base actions, expressions, speech, lighting, and facial recognition that are offered in the Cruzr's robot platform, companies can customize their own products according to their own specific application scenarios. This further expands the depth and breadth of applications for Cruzr.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
            <h2>Specifications</h2>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Grid container spacing={2} style={{ textAlign: 'left' }}>
              <Grid item xs={12} md={4} lg={4} >
                <Box><span style={{ fontWeight: 'bold' }}>Size :</span> 1195(H)*521(W)*516(D)mm</Box>
                <Box><span style={{ fontWeight: 'bold' }}>น้ำหนัก :</span> 45KG</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Color :</span> White + Silver</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Materials :</span> Plastics, Metal</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Battery :</span> 20Ah 24V</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Screen :</span> 11.6-inch touch screen</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Camera :</span> 13MP HD Photo, 1080p Video</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Speaker :</span> Two-channel stereo, Unit: 3 inch*2; Output power: 6W*2;</Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Box><span style={{ fontWeight: 'bold' }}>Sensors :</span> 6+0 MIC array in head 1 Depth-perception camera in waist 1 Lidar, 6 Sonar sensors, 12 IR sensors, 9-axis IMU in chassis</Box>
                <Box><span style={{ fontWeight: 'bold' }}>DoF :</span> 1 DoF head 5*2 DoF (L/R) arms 1*2 DoF (L/R) hands 1 DoF waist 3 Omni-wheels</Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4} >
                <Box><span style={{ fontWeight: 'bold' }}>OS :</span> Android & ROS</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Velocity :</span> Normal speed: 0.3m/s; Highest speed: 1m/s</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Networking :</span> Wi-Fi 2.4G/5G</Box>
                <Box><span style={{ fontWeight: 'bold' }}>Accessories :</span>1 Recharging dock, 1 power adapter, 1 user manual</Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <span style={{ fontWeight: 'bold' }}>Reference :</span>
                  <a href="https://ubtrobot.com/pages/cruzr" target="_blank">https://ubtrobot.com/pages/cruzr</a>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

      </Sidemenu>
    </div>
  )
}
