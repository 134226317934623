import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// import { AppBar, Toolbar, IconButton, Typography, InputBase, Badge, MenuItem, Menu, Link, Box, Grid } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'
import DashboardIcon from '@material-ui/icons/Dashboard'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import { useStyles } from './Sidemenu.style'

interface Size {
  width: number | undefined
  height: number | undefined
}

const dataList = [
  // {
  //   name: 'Dashboard',
  //   icon: <DashboardIcon />,
  //   path: '/product-detail'
  // },
  {
    name: 'Cruzr',
    icon: <InboxIcon />,
    path: '/product-detail/cruzr'
  },
  {
    name: 'Ocr',
    icon: <FindInPageIcon />,
    path: '/product-detail/ocr'
  },
  {
    name: 'Sentiment',
    icon: <SentimentSatisfiedIcon />,
    path: '/product-detail/sentiment'
  },
]
export default function Sidemenu(props: any) {
  const classes = useStyles()
  let history = useHistory()
  const theme = useTheme()
  const size = useWindowSize()
  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if ( size.width !== undefined && size.width < 960) {
      setOpen(false)
    }
  }, [size])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={2} md={2} style={{ textAlign: 'left' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1} md={8} className={classes.gridMenu}>
              <Box className={classes.sectionDesktop}>
                <Box display="flex" margin="auto" textAlign="center">
                  <Box className={classes.MenuItem}>
                    <Link href="/#home" className={classes.textItem}>
                      HOME
                    </Link>
                  </Box>
                  <Box className={classes.MenuItem}>
                    <Link href="/#about" className={classes.textItem}>
                      ABOUT
                    </Link>
                  </Box>
                  <Box className={classes.MenuItem}>
                    <Link href="/#product" className={classes.textItem}>
                      PRODUCT
                    </Link>
                  </Box>
                  <Box className={classes.MenuItem}>
                    <Link href="/#activity" className={classes.textItem}>
                      ACTIVITY
                    </Link>
                  </Box>
                  <Box className={classes.MenuItem}>
                    <Link href="/#contact" className={classes.textItem}>
                      CONTACT
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={9} md={2} style={{ textAlign: 'right' }}>
              <Link href="/">
                <img src={`${process.env.PUBLIC_URL}/image/public_a-logo.jpg`} alt="public_a-logo" className={classes.imageIcon} />
              </Link>
            </Grid>
          </Grid>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <Link href="/">
            <img src={`${process.env.PUBLIC_URL}/image/public_a-logo.jpg`} alt="public_a-logo" className={classes.imageIconBar} />
          </Link> */}
          <div style={{fontWeight:'bold'}}>
            AI LAB PRODUCTS
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {dataList.map((item: any, index) => (
            <Link href={item.path} key={item.name}>
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} style={{ color: '#5f5f5f', fontWeight: 600 }} />
              </ListItem>
            </Link>
          ))}
        </List>
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main className={classes.content}>
        {props.children}
      </main>
    </div >
  )
}

// Hook
function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}