import React from 'react'
import { Box, Grid, Card, CardActions, CardContent, CardMedia, CardActionArea, Divider, Typography } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import Sidemenu from './../../../component/Sidemenu/Sidemenu'
import { useStyles } from './Ocr.style'

export default function Ocr() {
  const classes = useStyles()
  return (
    <div>
      <Sidemenu>
        <Box className={classes.productdetailContent}>
          <Grid container>
            <Grid item xs={10} md={3} lg={2}>
              <Box className={classes.productTopic}>Ocr</Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container >
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Box style={{ marginBottom: '50px' }}>
              <h3 style={{ display: 'contents', fontWeight: 'bold' }}>Optical character recognition, or OCR</h3>
              , defines the process of mechanically or electronically converting scanned images of handwritten, typed or printed text into machine-encoded text.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <h2>PREPROCESSING</h2>
            <Box>
              OCR software often preprocesses images to improve the chances of a successful recognition. The aim of image preprocessing is an improvement of the image data. Thus unwanted distortions are suppressed and specific image features are enhanced. Both of which are important for further processing.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <h2>CHARACTER RECOGNITION</h2>
            <Box>
              For the actual character recognition part it is important to understand what feature extraction is. When the input data to an algorithm is too large to be processed, only a reduced set of features is selected. Those features selected are expected to be the important ones. The ones that are suspected to be redundant are sorted out. By using the reduced set of data instead of the initial large one, the performance will be better. For the process of OCR this is important because the algorithm has to detect specific portions or shapes of a digitized image or video stream.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <h2>POST-PROCESSING</h2>
            <Box>
              Post-processing is another error correction technique that ensures the high accuracy of OCR. The accuracy can be further improved if the output is restricted by a lexicon. That way the algorithm can fall back to a list of words that are allowed to occur in the scanned document for example.
            </Box>
            <Box>
              Also depending on the application OCR is not only used for proper words, but also for numbers and codes.To better deal with different types of input OCR providers started to develop specific OCR systems. Those systems are able to deal with the special images. To further improve the recognition accuracy they combined various optimization techniques. For example they used business rules, standard expressions or rich information contained in color image. The strategy of merging various optimization techniques is called “application oriented OCR” or “customized OCR”. It is used in fields like business card OCR, invoice OCR or ID card OCR.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
            <h2>Example</h2>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Grid container spacing={2} style={{ textAlign: 'left' }}>
              <Grid item xs={12} md={6} lg={6} >
                <Box><h2>Image</h2></Box>
                <Box display="flex" justifyContent="center">
                  <img src={`${process.env.PUBLIC_URL}/image/images/ocr_ailab.png`} width="50%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box><h2>Result OCR</h2></Box>
                <Box>
                  <img src={`${process.env.PUBLIC_URL}/image/images/ocr_2.png`} width="100%" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

      </Sidemenu>
    </div>
  )
}
