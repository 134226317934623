import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom"
import SinglePage from './pages/SinglePage/SinglePage'
import ProductDetail from './pages/ProductDetail/ProductDetail'
import NotFound from './pages/NotFound/NotFound'
import Cruzr from './pages/ProductDetail/Cruzr/Cruzr'
import Sentiment from './pages/ProductDetail/Sentiment/Sentiment'
import Ocr from './pages/ProductDetail/Ocr/Ocr'
import './App.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={SinglePage} />
          <Route exact path="/product-detail" component={ProductDetail} />
          <Route exact path="/product-detail/cruzr" component={Cruzr} />
          <Route exact path="/product-detail/ocr" component={Ocr} />
          <Route exact path="/product-detail/sentiment" component={Sentiment} />
          <Route path="/404" component={NotFound} />
          <Redirect to="/404"></Redirect>
        </Switch>
      </Router>
    </div>
  )
}

export default App
