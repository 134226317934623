import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  productBg: {
    width: '100%',
    height: '1000px',
    backgroundColor: '#272727',
  },
  cardProduct: {
    height: '550px',
    width: '350px',
    backgroundColor: '#363535',
    textAlign: 'left',
    transition: '0.3s',
    color: '#ffffff',
    margin: '30px auto',
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        boxShadow: '0 4px 16px 0 rgba(255, 255, 255, 0.2)',
        transform: 'scale(1.05)'
      }
    }
  },
  slide: {
    width: '70%',
    margin: '50px auto 0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  cardImgTopProduct: {
    height: '275px',
    width: 'auto'
  },
  cardTitleProduct: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '0.75rem'
  },
  cardTextProduct: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px'
  },
  cardFooterProduct: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    padding: '.75rem 1.25rem',
    backgroundColor: 'rgba(0,0,0,.03)',
    borderTop: '1px solid rgba(0,0,0,.125)'
  }
}))