import React from 'react'
import { Box, Grid, Card, CardActions, CardContent, CardMedia, CardActionArea, Divider, Typography } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import Sidemenu from '../../../component/Sidemenu/Sidemenu'
import { useStyles } from './Sentiment.style'

export default function Sentiment() {
  const classes = useStyles()
  return (
    <div>
      <Sidemenu>
        <Box className={classes.productdetailContent}>
          <Grid container>
            <Grid item xs={10} md={3} lg={2}>
              <Box className={classes.productTopic}>Sentiment</Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container >
          <Grid item xs={12} md={2} lg={2} />
          <Grid item xs={12} md={8} lg={8}>
            <Box style={{ marginBottom: '50px' }}>
              <h3 style={{ display: 'contents', fontWeight: 'bold' }}>Sentiment Analysis </h3>
              is used to discover people’s opinions, emotions and feelings about a product or service. In theory it is a computational study of opinions, sentiments, attitudes, views, emotions etc. expressed in text. This text can be in a variety of formats like Reviews, Blogs, News or Comments. The ability to extract insights from this type of data is a practice that is widely adopted by many organisations across the world. Its application are broad and powerful. Sentiment analysis has many names — Opinion Mining, Sentiment Mining, and Subjectivity Analysis.
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} />
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>

        <Grid container style={{ marginBottom: '50px' }}>
          <Grid container>
            <Grid item xs={12} md={2} lg={2} />
            <Grid item xs={12} md={8} lg={8}  style={{ marginBottom: '30px' }}>
              <h2>Why sentiment analysis is important?</h2>
              <Box>
                OCR software often preprocesses images to improve the chances of a successful recognition. The aim of image preprocessing is an improvement of the image data. Thus unwanted distortions are suppressed and specific image features are enhanced. Both of which are important for further processing.
            </Box>
            </Grid>
            <Grid item xs={12} md={2} lg={2} />
            <Grid item xs={12} md={3} lg={3} />
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <img src={`${process.env.PUBLIC_URL}/image/images/sentimentapp.jpg`} width="100%" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3} lg={3} />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: '30px' }}>
          <Grid item xs={12} md={1} lg={1} />
          <Grid item xs={12} md={10} lg={10}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={1} lg={1} />
        </Grid>
      </Sidemenu>
    </div>
  )
}
