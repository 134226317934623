import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  aboutBg: {
    width: '100%',
    backgroundColor: '#272727',
    [theme.breakpoints.up('md')]: {
      height: '1424px',

    },
  },
  aboutTopic: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px',
    },
  },
  aboutWhatImgAi: {
    height: '221px',
    marginTop: '40px',
    filter: 'drop-shadow(0px 4px 30px rgba(255, 255, 255, 0.4))'
  },
  aboutWhatAiContent: {
    paddingTop: '80px',
    paddingLeft: '5%',
    width: '70%',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  aboutUnderline: {
    marginTop: '120px',
    marginLeft: '5%',
    width: '65%',
    border: '1px solid #FFFFFF',
    [theme.breakpoints.down('md')]: {
      marginTop: '100px',
      margin: 'auto',
    },
  },
  aboutWhatImgVision: {
    height: '250px',
    filter: 'drop-shadow(0px 4px 30px rgba(255, 255, 255, 0.4))'
  },
  aboutWhatImgMission: {
    height: '250px',
    marginTop: '20px',
    filter: 'drop-shadow(0px 4px 30px rgba(255, 255, 255, 0.4))'
  }
}))