import React from 'react'
import { AppBar, Toolbar, IconButton, Typography, InputBase, Badge, MenuItem, Menu, Link, Box, Grid } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/Info'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import LocalActivityIcon from '@material-ui/icons/LocalActivity'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'

import { useStyles } from './Navbar.style'

interface INavbar {
  top: boolean
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const menuList = [
  {
    name: 'HOME',
    path: '/#home',
    icon: <HomeIcon />
  },
  {
    name: 'ABOUT',
    path: '/#about',
    icon: <InfoIcon />
  },
  {
    name: 'PRODUCT',
    path: '/#product',
    icon: <ChromeReaderModeIcon />
  },
  {
    name: 'ACTIVITY',
    path: '/#activity',
    icon: <LocalActivityIcon />
  },
  {
    name: 'CONTACT',
    path: '/#contact',
    icon: <ImportContactsIcon />
  },
]

export default function Navbar(props: INavbar) {
  const classes = useStyles()
  const { top } = props

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button>
          <ListItemIcon>
            <Link href="/">
              <img src={`${process.env.PUBLIC_URL}/image/public_a-logo.jpg`} alt="public_a-logo" className={classes.imageIcon} />
            </Link>
          </ListItemIcon>
          <ListItemText primary="AI LAB COMPANY LIMITED" />
        </ListItem>
        <Divider />
        {menuList.map((menu, index) => {
          return (
            <Link href={menu.path} className={classes.textItem}>
              <ListItem button key={`menu-${index}`}>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItem>
            </Link>
          )
        })}
      </List>
    </div>
  )

  const MenuDestop = () => {
    return (
      <AppBar className={top ? classes.NavbarTop : classes.Navbar}>
        <Toolbar className={top ? classes.navbarMinHeight : ''}>
          <Grid container spacing={3}>
            <Grid item xs={2} style={{ textAlign: 'left' }}>
              <Link href="/">
                <img src={`${process.env.PUBLIC_URL}/image/public_a-logo.jpg`} alt="public_a-logo" className={classes.imageIcon} />
              </Link>
            </Grid>
            <Grid item xs={8} className={top ? '' : classes.gridMenu}>
              <Box className={classes.sectionDesktop}>
                <Box display="flex" margin="auto" textAlign="center" className={top ? classes.menuItemPadding : ''}>
                  {menuList.map((menu, index) => {
                    return (
                      <Box className={top ? classes.MenuItemTop : classes.MenuItem} key={`menu-${index}`}>
                        <Link href={menu.path} className={top ? classes.textItemTop : classes.textItem}>
                          {menu.name}
                        </Link>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.sectionMobile}>
                <React.Fragment>
                  <IconButton edge="start" className={top ? classes.menuButtonTop : classes.menuButton} aria-label="menu" onClick={toggleDrawer('top', true)}>
                    <MenuIcon />
                  </IconButton>
                  <SwipeableDrawer
                    anchor={'top'}
                    open={state['top']}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                  >
                    {list('top')}
                  </SwipeableDrawer>
                </React.Fragment>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }

  return (
    <Box className={classes.grow}>
      <MenuDestop />
    </Box>
  )
}
