import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  contactBg: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '960px',
    },
    backgroundColor: '#000E57'
  },
  contactVerticalStraightLine: {
    position: 'absolute',
    left: '50%',
    height: '660px',
    [theme.breakpoints.up('lg')]: {
      borderLeft: '3px solid #FFFFFF',
      marginTop: '80px',
    },
  },
  cantactCompany: {
    marginLeft: '15px',
    marginTop: '100px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '25px'
  },
  contactAddress: {
    marginLeft: '15px',
    marginTop: '50px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px'
  },
  contactMap: {
    border: 0,
    marginTop: 150,
    width: 600,
    height: 450,
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },
  }
}))