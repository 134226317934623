import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useStyles } from './About.style'

// const dataList = [
//   {
//     name: 'What is AI.?',
//     imgPath: `${process.env.PUBLIC_URL}/image/cranium-2099120_1280_ai.png`,
//     description: 'is the most influential general-purpose technology in this era. With the rise of computing power and a noticebly growth of data, by utilizing the machine learning, the companies can run their bussinesses with high performance automatically.'
//   }
// ]

export default function About() {
  const classes = useStyles()

  return (
    <Box className={classes.aboutBg}>
      <Box className="text-title">
        About us
      </Box>
      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={5}>
          <Grid container style={{ textAlign: 'left' }}>
            <Grid item xs={12} md={5}/>
            <Grid item xs={12} md={7}>
              <Box className={classes.aboutTopic} >What is AI.?</Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}/>
            <Grid item xs={12} md={8}>
              <img src={`${process.env.PUBLIC_URL}/image/cranium-2099120_1280_ai.png`} alt="ai" className={classes.aboutWhatImgAi} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className={classes.aboutWhatAiContent}>
            <h4 style={{ display: 'contents', fontWeight: 'bold' }}>AI.</h4>
            is the most influential general-purpose technology in this era. With the rise of computing power and a noticebly growth of data, by utilizing the machine learning, the companies can run their bussinesses with high performance automatically.
          </Box>
          <Box className={classes.aboutUnderline} />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={5}>
          <Grid container style={{ textAlign: 'left' }}>
            <Grid item xs={12} md={5} />
            <Grid item xs={12} md={4}>
              <Box className={classes.aboutTopic} >Vision</Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={8}>
              <img src={`${process.env.PUBLIC_URL}/image/images/vision.png`} alt="vision" className={classes.aboutWhatImgVision} />
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className={classes.aboutWhatAiContent}>
            <h4 style={{ display: 'contents', fontWeight: 'bold' }}>"Aiming to be a leader The country's IT business "</h4>
            and is fully involved in the development of IT systems with a wide range of IT products and services and ready to provide consultation and design new documents.
          </Box>
          <Box className={classes.aboutUnderline} />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={5}>
          <Grid container style={{ textAlign: 'left' }}>
            <Grid item xs={12} md={5}/>
            <Grid item xs={12} md={4}>
              <Box className={classes.aboutTopic} >Mission</Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}/>
            <Grid item xs={12} md={8}>
              <img src={`${process.env.PUBLIC_URL}/image/images/mission.png`} alt="mission" className={classes.aboutWhatImgMission} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className={classes.aboutWhatAiContent}>
            <h4 style={{ display: 'contents', fontWeight: 'bold' }}>“As long as time does not stand still we will not stop developing”</h4>
            We will strive to develop and introduce quality new products, services and technologies under the worldwide recognized certification standards. To meet and create maximum satisfaction for customers and partners.
          </Box>
          <Box className={classes.aboutUnderline} />
        </Grid>
      </Grid>
    </Box>
  )
}
