import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  productdetailContent: {
    color: '#000000',
    paddingTop: '60px'
  },
  productTopic: {
    textAlign: 'left',
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '30px',
    background: 'linear-gradient(270deg, #fafafa 0%, rgba(255, 255, 255, 0) 50%), #E0E0E0',
    padding: '10px 15px'
  },
  cardCruzr: {
    height: '400px',
    backgroundColor: '#363535',
    textAlign: 'left',
    transition: '0.3s',
    color: '#ffffff',
    margin: '30px auto',
    [theme.breakpoints.down('xs')]: {
      height: '150px',
    }
  },
  slide: {
    position: 'absolute',
    width: '75%',
    margin: '50px auto 0px auto',
  },
  cardImgTopProduct: {
    height: '400px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '150px',
    }
  },
  gridSlide: {
    marginBottom: '560px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '310px',
    }
  }
}))
