import React, { useEffect, useState } from 'react'
import About from './About/About'
import Activity from './Activity/Activity'
import Contact from './Contact/Contact'
import Home from './Home/Home'
import Product from './Product/Product'
import NavBar from './../../component/Navbar/Navbar'
import { useStyles } from './SinglePage.style'


export default function SinglePage() {
  const classes = useStyles()
  const [isTop, setTop] = useState<boolean>(true)

  const onScroll = (e: any) => {
    if (e.target.documentElement.scrollTop >= 60) {
      setTop(false)
    } else {
      setTop(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
  }, [])

  return (
    <div onScroll={onScroll} style={{backgroundColor:'black'}}>
      <NavBar top={isTop} />
      <header id="home">
        <Home />
      </header>
      <section id="about">
        <About />
      </section>
      <section id="product">
        <Product />
      </section>
      <section id="activity">
        <Activity />
      </section>
      <footer id="contact">
        <Contact />
      </footer>
    </div>
  )
}
