import React from 'react'
import { Box, Button, Link } from '@material-ui/core'

export default function NotFound() {
  return (
    <Box display="flex" alignItems="center" justifyItems="center" height="100vh">
      <Box alignItems="center" justifyItems="center" margin="auto" >
        <Box display="block">404 | Not Found </Box>
        <Box display="block">
          <Link href="/">
            <Button variant="contained" color="primary">Home Page</Button>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
