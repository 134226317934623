import { makeStyles, fade } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:'#1E1C8B'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  imageIcon: {
    width: '40px',
    height: '40px',
    marginRight: 0,
    borderRadius: '10px',
    cursor: 'pointer'
  },
  imageIconBar: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  MenuItem: {
    minWidth: '150px',
    cursor: 'pointer',
  },
  textItem: {
    margin: 'auto',
    color: '#ffffff',
    userSelect: 'none',
    fontSize: '15px',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      color: '#cfcfcf',
    }
  },
  navbarMinHeight: {
    minHeight: '100px'
  },
  menuItemPadding: {
    paddingTop: '20px'
  },
  gridMenu: {
    display: 'flex',
    justifyContent: 'center'
  }
}))