import React, { useEffect, useState } from 'react'
import { Box, Grid, Card, CardActions, CardContent, CardMedia, CardActionArea, Button, Typography } from '@material-ui/core'
import Slider from 'react-slick'
import { useStyles } from './Activity.style'

import './Activity.css'

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  autoplay: false,
  autoplaySpeed: 6000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1980,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: true
      }
    },
    {
      breakpoint: 840,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false
      }
    }
  ]
}

const dataList = [
  // {
  //   title: "ทำดีเพื่อชาติ",
  //   description: "กิจกรรมทำดีเพื่อชาติ เป็นกิจกรรมที่ทางทีม Ai Lab ได้เข้าร่วม และได้ทำกิจกรรมร่วมกับทั้งทางภาครัฐและภาคเอกชนต่าง ๆ โดยมีตัวแทนคณะรัฐมนตรีว่าการกระทรวงต่างประเทศมาร่วมงานในครั้งนี้ด้วย ทั้งนี้จุดประสงค์ของงานคือต้องการให้ทุกคนหันมารักชาติบ้านเมื่อง รวมถึงการเป็นประชาธิปไตย ที่ไม่มีการคดโกงใด ๆ",
  //   imagePath: `${process.env.PUBLIC_URL}/image/images/506864.jpg`,
  //   titleName: "กิจกรรมทำดีเพื่อชาติ",
  //   date: "20/07/2020"
  // },
  // {
  //   title: "ทำดีเพื่อศาสนา",
  //   description: "กิจกรรมทำดีเพื่อศาสนา เป็นกิจกรรมที่ทางทีม Ai Lab ได้เข้าร่วม และได้ทำกิจกรรมร่วมกับทั้งทางภาครัฐและภาคเอกชนต่าง ๆ โดยมีตัวแทนคณะรัฐมนตรีว่าการกระทรวงต่างประเทศมาร่วมงานในครั้งนี้ด้วย ทั้งนี้จุดประสงค์ของงานคือต้องการให้ทุกคนหันมารักชาติบ้านเมื่อง รวมถึงการเป็นประชาธิปไตย ที่ไม่มีการคดโกงใด ๆ",
  //   imagePath: `${process.env.PUBLIC_URL}/image/images/506866.jpg`,
  //   titleName: "กิจกรรมทำดีเพื่อศาสนา",
  //   date: "20/07/2020"
  // },
  // {
  //   title: "ทำดีเพื่อประชาชน",
  //   description: "กิจกรรมทำดีเพื่อประชาชน เป็นกิจกรรมที่ทางทีม Ai Lab ได้เข้าร่วม และได้ทำกิจกรรมร่วมกับทั้งทางภาครัฐและภาคเอกชนต่าง ๆ โดยมีตัวแทนคณะรัฐมนตรีว่าการกระทรวงต่างประเทศมาร่วมงานในครั้งนี้ด้วย ทั้งนี้จุดประสงค์ของงานคือต้องการให้ทุกคนหันมารักชาติบ้านเมื่อง รวมถึงการเป็นประชาธิปไตย ที่ไม่มีการคดโกงใด ๆ",
  //   imagePath: `${process.env.PUBLIC_URL}/image/images/506863.jpg`,
  //   titleName: "กิจกรรมทำดีเพื่อประชาชน",
  //   date: "20/07/2020"
  // },
  // {
  //   title: "ทำดีเพื่อประชาธิปไตย",
  //   description: "กิจกรรมทำดีเพื่อประชาธิปไตย เป็นกิจกรรมที่ทางทีม Ai Lab ได้เข้าร่วม และได้ทำกิจกรรมร่วมกับทั้งทางภาครัฐและภาคเอกชนต่าง ๆ โดยมีตัวแทนคณะรัฐมนตรีว่าการกระทรวงต่างประเทศมาร่วมงานในครั้งนี้ด้วย ทั้งนี้จุดประสงค์ของงานคือต้องการให้ทุกคนหันมารักชาติบ้านเมื่อง รวมถึงการเป็นประชาธิปไตย ที่ไม่มีการคดโกงใด ๆ",
  //   imagePath: `${process.env.PUBLIC_URL}/image/images/506865.jpg`,
  //   titleName: "กิจกรรมทำดีเพื่อประชาธิปไตย",
  //   date: "20/07/2020"
  // },
  {
    title: "Ai lab activity",
    description: "Ai lab activity detail",
    imagePath: `${process.env.PUBLIC_URL}/image/activity-ailab.png`,
    titleName: "Ai lab activity",
    date: "20/07/2020"
  },
  {
    title: "Ai lab activity",
    description: "Ai lab activity detail",
    imagePath: `${process.env.PUBLIC_URL}/image/activity-ailab.png`,
    titleName: "Ai lab activity",
    date: "20/07/2020"
  },
  {
    title: "Ai lab activity",
    description: "Ai lab activity detail",
    imagePath: `${process.env.PUBLIC_URL}/image/activity-ailab.png`,
    titleName: "Ai lab activity",
    date: "20/07/2020"
  },
  {
    title: "Ai lab activity",
    description: "Ai lab activity detail",
    imagePath: `${process.env.PUBLIC_URL}/image/activity-ailab.png`,
    titleName: "Ai lab activity",
    date: "20/07/2020"
  },
  {
    title: "Ai lab activity",
    description: "Ai lab activity detail",
    imagePath: `${process.env.PUBLIC_URL}/image/activity-ailab.png`,
    titleName: "Ai lab activity",
    date: "20/07/2020"
  },
  {
    title: "Ai lab activity",
    description: "Ai lab activity detail",
    imagePath: `${process.env.PUBLIC_URL}/image/activity-ailab.png`,
    titleName: "Ai lab activity",
    date: "20/07/2020"
  },
  
]

export default function Activity() {
  const classes = useStyles()
  const [newDataList, setNewDataList] = useState<any>([])

  useEffect(() => {
    let arrayTemp: any = []
    while(dataList.length) arrayTemp.push(dataList.splice(0,2));
    setNewDataList(arrayTemp)
  }, [])

  return (
    <div className="activity-bg">
      <div className="text-title">
        Activity
      </div>
      <div className={classes.slide}>
        <Slider {...settings}>
          {newDataList.map((listItem: any, indexs: any) => {
            return (
              <Box key={`object-${indexs}`}>
                {listItem.map((item: any, index: any) => {
                  return (
                    <div className="card card-activity" style={{ marginBottom: 50, marginTop: 30 }} key={`data-${index}`}>
                      <div className="activity-detail activity-detail-hide">
                        <div className="activity-detail-title">
                          {item.title}
                        </div>
                        <div className="activity-detail-desc container">
                          {item.description}
                        </div>
                      </div>
                      <img className="card-img-top card-img-top-activity" src={item.imagePath} />
                      <div className="card-footer card-footer-activity">
                        <div className="activity-title">
                          {item.titleName}
                        </div>
                        <div className="activity-date">
                          {item.date}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Box>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

// <div className={classes.activityBg}>
//       <div className="text-title">
//         Activity
//             </div>
//       <div style={{ width: '70%', margin: 'auto', marginTop: 30 }}>
//         <Slider {...settings}>
//           {newDataList.map((listItem: any, indexs: any) => {
//             return (
//               <Box key={`object-${indexs}`}>
//                 {listItem.map((item: any, index: any) => {
//                   return (
//                     <div className={classes.cardActivity} style={{ marginBottom: 50, marginTop: 30 }} key={`data-${index}`}>
//                       <div className={classes.activityDetail}>
//                         <div className={classes.activityDetailTitle}>
//                           {item.title}
//                         </div>
//                         <div className={classes.activityDetailDesc}>
//                           {item.description}
//                         </div>
//                       </div>
//                       <img className={classes.cardImgTopActivity} src={item.imagePath} />
//                       <div className={classes.cardFooterActivity}>
//                         <div className={classes.activityTitle}>
//                           {item.titleName}
//                         </div>
//                         <div className={classes.activityDate}>
//                           {item.date}
//                         </div>
//                       </div>
//                     </div>
//                   )
//                 })}
//               </Box>
//             )
//           })}
//         </Slider>
//       </div>
//     </div>